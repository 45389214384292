.btn {
	border-radius: 99px;
	font-weight: 600;
	padding: 10px 24px 10px 24px;
	font-size: 16px;
	line-height: 24px;
	height: 44px;

	&:hover {
		text-decoration: underline;
	}
}

.btn-primary {
	border: 0px;

	&:hover {
		background-color: #001266;
		color: #FFF;
	}

	&:active {
		background-color: #001266;
		color: white;
	}

	&:focus {
		background-color: #003087;
		outline: none;
		outline: 2px solid #1560BD;
	}
}

.coupon-btn {
	padding: 0em;
	height: 58px;
}

.light-font-color {
	color: #333333;
}

.home-compare-section .combo-row td:first-child {
	color: #6F6F6F;
}

.question-letter {
	color: #0467C6;
	font-size: 2em;
}

.answer-letter {
	font-size: 2em;
}

h1 {
	color: #111111;
}

.btn-primary-alt {
	background-color: #FFF;
	border-color: #003087;
	border: 2px solid;
	color: #001266;

	&:hover {
		background-color: #F2F9FF;
		color: #001266;
		border-color: #001266;
		border: 2px solid;
	}

	&:focus {
		background-color: #FFF;
		outline: 2px solid #1560BD;
		border: 2px solid #001266;
		color: #001266;
	}

	&:active {
		background-color: #F2F9FF !important;
		border-color: #001266;
		border: 2px solid;
		color: #001266 !important;
	}
}

.red-background {
	background-color: #E4002B;
}

.color-light-blue {
	color: #2C73CA;
}

.modal-wide-modal {
	max-width: 30%;
}

.snap-step-name {
	color: #A7A7A7;
	font-weight: 600;
}

.snap-stepper-item.completed .snap-step-counter {
	background-color: #c60025 !important;
}

.snap-stepper-item.completed .snap-step-name {
	color: #2A2A2A !important;
}

.snap-stepper-item .snap-step-counter {
	background-color: #A7A7A7 !important;
	color: white;
	height: 21px;
	width: 21px;
}

.snap-stepper-item.completed::after {
	border-bottom: 2px solid #e4002b !important;
}

h1 {
	font-weight: 600;
	font-size: 48px;
	line-height: 56px;
	color: #2A2A2A;
}

h2 {
	font-weight: 600;
	font-size: 32px;
	line-height: 40px;
	color: #2A2A2A;
}

h3 {
	font-weight: 600;
	font-size: 28px;
	line-height: 36px;
	color: #2A2A2A;
}

h4 {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #2A2A2A;
}

h5 {
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: #2A2A2A;
}

.p-large {
		font-weight: 400;
		font-size: 20px;
		line-height: 28px;
		color: #434343;
}

p {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #434343;
}

.p-bold {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #434343;
}

.p-link {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #003087;

	&:hover {
		color: #001266;
		text-decoration: underline;
	}
}

.snap-background {
	background-color: white;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	color: #2A2A2A;
}

.account-button {
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	color: #2A2A2A !important;
}

.home-hero-text-section {
	.btn-primary-alt {
		&:active {
			background-color: #FFF;
			border: 2px solid #1560BD;
			color: #001266;
		}
	}
}

.ca-post-130-ml {
	margin-left: 130px;

	@media (max-width: 800px) {
		margin-left: 0px;
	}
}

.ca-post-logo-ml {
	margin-left: 130px;

	@media (max-width: 1200px) {
		margin-left: 0px;
	}
}

.ca-post-130-mr {
	margin-right: 130px;

	@media (max-width: 800px) {
		margin-right: 0px;
	}
}

.ca-post-24-ml {
	margin-left: 24px;
}

.ca-post-24-mr {
	margin-right: 24px;
}

.ca-post-24-pl {
	padding-left: 24px;
}

.ca-post-24-pr {
	padding-right: 24px;
}

.grey-divider {
	border-right: 1px solid #D9D9D9;
}

.header-nav-button {
	background-color: #003087 !important;
	color: white !important;
	height: 36px;
	padding: 8px 16px 8px 16px;

	&:hover {
		background-color: #001266;
		color: white;
	}

	&:active {
		background-color: #001266;
		color: white;
	}

	&:focus {
		color: white;
		background-color: #003087;
		outline: none;
		outline: 2px solid #1560BD;
	}
}

.btn-success {
	background-color: #003087 !important;
	color: white !important;

	&:hover {
		background-color: #001266;
		color: white;
	}

	&:active {
		background-color: #001266;
		color: white;
	}

	&:focus {
		color: white;
		background-color: #003087;
		outline: none;
		outline: 2px solid #1560BD;
	}
}

.shopping {
	.form-control, .form-select {

		&:focus {
			border-color: #000;
			outline: none;
			box-shadow: none !important;
		}
	}

	.channel-button {
		&:hover:not(.selected) {
			border: 1px solid #317aff;
			box-shadow: none !important;
		}
	}

	.btn-background {
		&:focus {
			border-color: #000;
			outline: none;
			box-shadow: none !important;
		}
	}
}

.dropdown-toggle {
	height: inherit !important;
	border-radius: 5px;
}

.custom-slider .ngx-slider .ngx-slider-bar {
	background: #c60025 !important;
}

.dropdown-item.active, .dropdown-item:active {
	background-color: #a7a7a7;
}

.alert-promo-with-close-button {
	background-color: #1560BD;
}
