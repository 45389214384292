@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-Light.otf');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-LightItalic.otf');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-Light.otf');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-LightItalic.otf');
	font-weight: 200;
	font-style: italic;
}


@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-Light.otf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-LightItalic.otf');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-Regular.otf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-RegularItalic.otf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-SemiBold.otf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-SemiBoldItalic.otf');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-SemiBold.otf');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-SemiBoldItalic.otf');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-Bold.otf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-BoldItalic.otf');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-Bold.otf');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Canadiana';
	src: url('/assets/sites/fonts/CanadianaText-BoldItalic.otf');
	font-weight: 800;
	font-style: italic;
}
